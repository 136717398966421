var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Locations")])]),_c('CCardBody',[_c('div',{staticClass:"clearfix"},[_c('button',{staticClass:"btn btn-primary float-xl-right",attrs:{"type":"button"},on:{"click":_vm.navToNewLocation}},[_vm._v(" New ")])]),_c('CDataTable',{ref:"vuetable",attrs:{"items":_vm.items,"fields":_vm.fields,"striped":"","clickable-rows":"","column-filter":"","table-filter":"","items-per-page-select":"","items-per-page":10,"sorter":"","pagination":"","responsive":false},scopedSlots:_vm._u([{key:"index",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(data.index + 1)+" ")])]}},{key:"createdTime",fn:function({ item }){return [(item.createdTime)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm._f("dateParse")(item.createdTime),"MMM D, YYYY hh:mm A"))+" ")]):_c('td',[_vm._v("--")])]}},{key:"show_details",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[_c('CDropdown',{attrs:{"togglerText":"Actions","color":"info"}},[_c('CDropdownItem',{attrs:{"to":{
                  name: 'MEDLocation',
                  params: { medLocationId: item.medLocationId, mode: 'view' },
                }}},[_vm._v("View")]),_c('ConfirmationModal',{attrs:{"parent":_vm.$refs.modalArea,"description":'Are you sure to Delete'},on:{"on:ok":function($event){return _vm.deleteMEDLocation(item.medLocationId)}}})],1)],1)]}}])})],1),_c('div',{ref:"modalArea"})],1),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Tyre Size")])]),_c('CCardBody',[_c('div',{staticClass:"clearfix"},[_c('button',{staticClass:"btn btn-primary float-xl-right",attrs:{"type":"button"},on:{"click":_vm.navToNewSize}},[_vm._v(" New ")])]),_c('CDataTable',{ref:"vuetyreSizeTable",attrs:{"items":_vm.sizeItems,"fields":_vm.sizeFields,"striped":"","clickable-rows":"","column-filter":"","table-filter":"","items-per-page-select":"","items-per-page":10,"sorter":"","pagination":"","responsive":false},scopedSlots:_vm._u([{key:"index",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(data.index + 1)+" ")])]}},{key:"createdTime",fn:function({ item }){return [(item.createdTime)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm._f("dateParse")(item.createdTime),"MMM D, YYYY hh:mm A"))+" ")]):_c('td',[_vm._v("--")])]}},{key:"show_details",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[_c('CDropdown',{attrs:{"togglerText":"Actions","color":"info"}},[_c('CDropdownItem',{attrs:{"to":{
                  name: 'TyreSize',
                  params: { tyreSizeId: item.tyreSizeId, mode: 'view' },
                }}},[_vm._v("View")]),_c('ConfirmationModal',{attrs:{"parent":_vm.$refs.modalArea,"description":'Are you sure to Delete'},on:{"on:ok":function($event){return _vm.deleteTyreSize(item.tyreSizeId)}}})],1)],1)]}}])})],1),_c('div',{ref:"modalArea"})],1),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Tyre Position")])]),_c('CCardBody',[_c('div',{staticClass:"clearfix"},[_c('button',{staticClass:"btn btn-primary float-xl-right",attrs:{"type":"button"},on:{"click":_vm.navToNewPosition}},[_vm._v(" New ")])]),_c('CDataTable',{ref:"vuetyrePositionTable",attrs:{"items":_vm.positionItems,"fields":_vm.positionFields,"striped":"","clickable-rows":"","column-filter":"","table-filter":"","items-per-page-select":"","items-per-page":10,"sorter":"","pagination":"","responsive":false},scopedSlots:_vm._u([{key:"index",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(data.index + 1)+" ")])]}},{key:"createdTime",fn:function({ item }){return [(item.createdTime)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm._f("dateParse")(item.createdTime),"MMM D, YYYY hh:mm A"))+" ")]):_c('td',[_vm._v("--")])]}},{key:"show_details",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[_c('CDropdown',{attrs:{"togglerText":"Actions","color":"info"}},[_c('CDropdownItem',{attrs:{"to":{
                  name: 'TyrePosition',
                  params: {
                    tyrePositionId: item.tyrePositionId,
                    mode: 'view',
                  },
                }}},[_vm._v("View")]),_c('ConfirmationModal',{attrs:{"parent":_vm.$refs.modalArea,"description":'Are you sure to Delete'},on:{"on:ok":function($event){return _vm.deleteTyrePosition(item.tyrePositionId)}}})],1)],1)]}}])})],1),_c('div',{ref:"modalArea"})],1),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Reason for Removal")])]),_c('CCardBody',[_c('div',{staticClass:"clearfix"},[_c('button',{staticClass:"btn btn-primary float-xl-right",attrs:{"type":"button"},on:{"click":_vm.navToNewReason}},[_vm._v(" New ")])]),_c('CDataTable',{ref:"vuetyreReasonTable",attrs:{"items":_vm.reasonItems,"fields":_vm.reasonFields,"striped":"","clickable-rows":"","column-filter":"","table-filter":"","items-per-page-select":"","items-per-page":10,"sorter":"","pagination":"","responsive":false},scopedSlots:_vm._u([{key:"index",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(data.index + 1)+" ")])]}},{key:"createdTime",fn:function({ item }){return [(item.createdTime)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm._f("dateParse")(item.createdTime),"MMM D, YYYY hh:mm A"))+" ")]):_c('td',[_vm._v("--")])]}},{key:"show_details",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[_c('CDropdown',{attrs:{"togglerText":"Actions","color":"info"}},[_c('CDropdownItem',{attrs:{"to":{
                  name: 'TyreRemovalReason',
                  params: {
                    tyreRemovalReasonId: item.tyreRemovalReasonId,
                    mode: 'view',
                  },
                }}},[_vm._v("View")]),_c('ConfirmationModal',{attrs:{"parent":_vm.$refs.modalArea,"description":'Are you sure to Delete'},on:{"on:ok":function($event){return _vm.deleteTyreRemovalReason(item.tyreRemovalReasonId)}}})],1)],1)]}}])})],1),_c('div',{ref:"modalArea"})],1),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Type of New Name")])]),_c('CCardBody',[_c('div',{staticClass:"clearfix"},[_c('button',{staticClass:"btn btn-primary float-xl-right",attrs:{"type":"button"},on:{"click":_vm.navToNewType}},[_vm._v(" New ")])]),_c('CDataTable',{ref:"vuetyreNewType",attrs:{"items":_vm.newTypeItems,"fields":_vm.newTypeFields,"striped":"","clickable-rows":"","column-filter":"","table-filter":"","items-per-page-select":"","items-per-page":10,"sorter":"","pagination":"","responsive":false},scopedSlots:_vm._u([{key:"index",fn:function(data){return [_c('td',[_vm._v(" "+_vm._s(data.index + 1)+" ")])]}},{key:"createdTime",fn:function({ item }){return [(item.createdTime)?_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm._f("dateParse")(item.createdTime),"MMM D, YYYY hh:mm A"))+" ")]):_c('td',[_vm._v("--")])]}},{key:"show_details",fn:function({ item }){return [_c('td',{staticClass:"py-2"},[_c('CDropdown',{attrs:{"togglerText":"Actions","color":"info"}},[_c('CDropdownItem',{attrs:{"to":{
                  name: 'TyreNewType',
                  params: { tyreNewTypeId: item.tyreNewTypeId, mode: 'view' },
                }}},[_vm._v("View")]),_c('ConfirmationModal',{attrs:{"parent":_vm.$refs.modalArea,"description":'Are you sure to Delete'},on:{"on:ok":function($event){return _vm.deleteTyreNewType(item.tyreNewTypeId)}}})],1)],1)]}}])})],1),_c('div',{ref:"modalArea"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }