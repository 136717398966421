<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Locations</strong>
      </CCardHeader>
      <CCardBody>
        <div class="clearfix">
          <button
            type="button"
            class="btn btn-primary float-xl-right"
            @click="navToNewLocation"
          >
            New
          </button>
        </div>

        <CDataTable
          :items="items"
          :fields="fields"
          striped
          clickable-rows
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="10"
          sorter
          ref="vuetable"
          pagination
          :responsive="false"
        >
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>

          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>

          <template #show_details="{ item }">
            <td class="py-2">
              <CDropdown togglerText="Actions" color="info">
                <CDropdownItem
                  :to="{
                    name: 'MEDLocation',
                    params: { medLocationId: item.medLocationId, mode: 'view' },
                  }"
                  >View</CDropdownItem
                >

                <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteMEDLocation(item.medLocationId)"
                ></ConfirmationModal>
              </CDropdown>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>Tyre Size</strong>
      </CCardHeader>
      <CCardBody>
        <div class="clearfix">
          <button
            type="button"
            class="btn btn-primary float-xl-right"
            @click="navToNewSize"
          >
            New
          </button>
        </div>

        <CDataTable
          :items="sizeItems"
          :fields="sizeFields"
          striped
          clickable-rows
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="10"
          sorter
          ref="vuetyreSizeTable"
          pagination
          :responsive="false"
        >
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>

          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>

          <template #show_details="{ item }">
            <td class="py-2">
              <CDropdown togglerText="Actions" color="info">
                <CDropdownItem
                  :to="{
                    name: 'TyreSize',
                    params: { tyreSizeId: item.tyreSizeId, mode: 'view' },
                  }"
                  >View</CDropdownItem
                >

                <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteTyreSize(item.tyreSizeId)"
                ></ConfirmationModal>
              </CDropdown>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>Tyre Position</strong>
      </CCardHeader>
      <CCardBody>
        <div class="clearfix">
          <button
            type="button"
            class="btn btn-primary float-xl-right"
            @click="navToNewPosition"
          >
            New
          </button>
        </div>

        <CDataTable
          :items="positionItems"
          :fields="positionFields"
          striped
          clickable-rows
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="10"
          sorter
          ref="vuetyrePositionTable"
          pagination
          :responsive="false"
        >
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>

          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>

          <template #show_details="{ item }">
            <td class="py-2">
              <CDropdown togglerText="Actions" color="info">
                <CDropdownItem
                  :to="{
                    name: 'TyrePosition',
                    params: {
                      tyrePositionId: item.tyrePositionId,
                      mode: 'view',
                    },
                  }"
                  >View</CDropdownItem
                >

                <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteTyrePosition(item.tyrePositionId)"
                ></ConfirmationModal>
              </CDropdown>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>Reason for Removal</strong>
      </CCardHeader>
      <CCardBody>
        <div class="clearfix">
          <button
            type="button"
            class="btn btn-primary float-xl-right"
            @click="navToNewReason"
          >
            New
          </button>
        </div>

        <CDataTable
          :items="reasonItems"
          :fields="reasonFields"
          striped
          clickable-rows
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="10"
          sorter
          ref="vuetyreReasonTable"
          pagination
          :responsive="false"
        >
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>

          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>

          <template #show_details="{ item }">
            <td class="py-2">
              <CDropdown togglerText="Actions" color="info">
                <CDropdownItem
                  :to="{
                    name: 'TyreRemovalReason',
                    params: {
                      tyreRemovalReasonId: item.tyreRemovalReasonId,
                      mode: 'view',
                    },
                  }"
                  >View</CDropdownItem
                >
                <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteTyreRemovalReason(item.tyreRemovalReasonId)"
                ></ConfirmationModal>
              </CDropdown>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>
    </CCard>
    <CCard>
      <CCardHeader>
        <strong>Type of New Name</strong>
      </CCardHeader>
      <CCardBody>
        <div class="clearfix">
          <button
            type="button"
            class="btn btn-primary float-xl-right"
            @click="navToNewType"
          >
            New
          </button>
        </div>

        <CDataTable
          :items="newTypeItems"
          :fields="newTypeFields"
          striped
          clickable-rows
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="10"
          sorter
          ref="vuetyreNewType"
          pagination
          :responsive="false"
        >
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>

          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>

          <template #show_details="{ item }">
            <td class="py-2">
              <CDropdown togglerText="Actions" color="info">
                <CDropdownItem
                  :to="{
                    name: 'TyreNewType',
                    params: { tyreNewTypeId: item.tyreNewTypeId, mode: 'view' },
                  }"
                  >View</CDropdownItem
                >
                <ConfirmationModal
                  :parent="$refs.modalArea"
                  :description="'Are you sure to Delete'"
                  @on:ok="deleteTyreNewType(item.tyreNewTypeId)"
                ></ConfirmationModal>
              </CDropdown>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>
    </CCard>
  </div>
</template>

<script>
const fields = [
  "index",
  {
    key: "medLocationName",
    label: "Locations",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },

  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
const sizeFields = [
  "index",
  {
    key: "tyreSizeName",
    label: "Tyre Size",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },

  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
const positionFields = [
  "index",
  {
    key: "tyrePositionName",
    label: "Tyre Position",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },

  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
const reasonFields = [
  "index",
  {
    key: "tyreRemovalReason",
    label: "Reason for Removal",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },

  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
const newTypeFields = [
  "index",
  {
    key: "tyreNewTypeName",
    label: "Type of New Name",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },

  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "MEDMetadata",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      sizeItems: [],
      positionItems: [],
      reasonItems: [],
      newTypeItems: [],
      fields,
      sizeFields,
      positionFields,
      reasonFields,
      newTypeFields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getLocations() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/med/locations"
      )
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
    getTyreSize() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/tyreSizes"
      )
        .then((response) => response.json())
        .then((data) => (this.sizeItems = data));
    },
    getTyrePosition() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/tyrePositions"
      )
        .then((response) => response.json())
        .then((data) => (this.positionItems = data));
    },
    getTyreReamovalReason() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/all/tyreRemovalReason"
      )
        .then((response) => response.json())
        .then((data) => (this.reasonItems = data));
    },
    getTyreNewType() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/tyreNewType"
      )
        .then((response) => response.json())
        .then((data) => (this.newTypeItems = data));
    },
    deleteMEDLocation(medLocationId) {
      console.log("delete called with", medLocationId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/med/location/" +
          medLocationId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getLocations();
          this.$nextTick(() => {
            this.$refs.vuetable.refresh();
          });
          window.location.reload();
          console.log(window.location.reload());
        });
    },
    deleteTyreSize(tyreSizeId) {
      console.log("delete called with", tyreSizeId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/med/tyre/size/" +
          tyreSizeId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getTyreSize();
          this.$nextTick(() => {
            this.$refs.vuetyreSizeTable.refresh();
          });
          window.location.reload();
        });
    },
    deleteTyrePosition(tyrePositionId) {
      console.log("delete called with", tyrePositionId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/med/tyre/position/" +
          tyrePositionId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getTyrePosition();
          this.$nextTick(() => {
            this.$refs.vuetyrePositionTable.refresh();
          });
          window.location.reload();
        });
    },
    deleteTyreRemovalReason(tyreRemovalReasonId) {
      console.log("delete called with", tyreRemovalReasonId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/med/tyre/removalReason/" +
          tyreRemovalReasonId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getTyreReamovalReason();
          this.$nextTick(() => {
            this.$refs.vuetyreReasonTable.refresh();
          });
          window.location.reload();
        });
    },

    deleteTyreNewType(tyreNewTypeId) {
      console.log("delete called with", tyreNewTypeId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/med/tyre/newType/" +
          tyreNewTypeId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getTyreNewType();
          this.$nextTick(() => {
            this.$refs.vuetyreNewType.refresh();
          });
          window.location.reload();
        });
    },

    navToNewLocation() {
      this.$router.push({ name: "NewMEDLocation", params: { mode: "new" } });
    },
    navToNewSize() {
      this.$router.push({ name: "NewTyreSize", params: { mode: "new" } });
    },
    navToNewPosition() {
      this.$router.push({ name: "NewTyrePosition", params: { mode: "new" } });
    },
    navToNewReason() {
      this.$router.push({
        name: "NewTyreRemovalReason",
        params: { mode: "new" },
      });
    },
    navToNewType() {
      this.$router.push({ name: "NewTyreNewType", params: { mode: "new" } });
    },
  },
  mounted() {
    this.getLocations();
    this.getTyreSize();
    this.getTyrePosition();
    this.getTyreReamovalReason();
    this.getTyreNewType();
  },
};
</script>